import apiNewton from "./apiNewton";

export const getStudentsDiagnosis = (params) =>
	apiNewton.get("groupTeachers/selectStudentsDiagnosisByIdGroup", {
		params: params,
	});

export const getSemesterTeacher = (idDepartament) =>
	apiNewton.get(`semesters/selectSemesterTeacher/${idDepartament}`);

export const getGroupBySemester = (params) =>
	apiNewton.get(
		`groupTeachers/selectGroupBySemester/${params.idDepartament}/${params.idUser}/${params.idSemester}`
	);

export const setStudentsDiagnosis = (data) =>
	apiNewton.patch("groupTeachers/updateStudentsDiagnosis", data);
