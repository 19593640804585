<template>
  <v-dialog
    v-model="dialogUpload"
    persistent
    min-width="500px"
    max-height="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text class="pt-4">
        <v-card class="fill-width mb-3" outlined elevation="0">
          <v-row class="ml-1">
            <v-col cols="12" sm="6" md="6">
              <p class="font-weight-medium text-h6 mb-0" style="color: #1e88e5">
                Carga de Notas
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Grupo: <span class="caption">{{ group.nameGroup }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Tipo de Evaluación:
                <span class="caption">{{
                  group.type === 0 ? "Simulacro" : "Exámen"
                }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Mes: <span class="caption">{{ group.month }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="grades"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption td-click"
          fixed-header
          :items-per-page="-1"
          height="550"
          hide-default-footer
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="saveGrades" :loading="loading" color="primary"
          ><v-icon right dark> mdi-content-save </v-icon>Guardar</v-btn
        >
        <v-btn :disabled="loading" @click="closeDialogUpload">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    grades: {
      type: Array,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    teacher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogUpload: false,
      loading: false,
    };
  },
  watch: {
    dialog(newValue) {
      console.log(newValue);
      this.dialogUpload = newValue;
    },
  },
  methods: {
    closeDialogUpload() {
      this.$emit("closeDialogUpload");
    },
    async insertStudentsGrade(data) {
      try {
        const response = await axios.post("courses/insertStudentsGrade", data);
        if (response.status == 200) {
          this.loading = false;
          const data = {
            type: "success",
            text: "Se registro las notas",
            students: this.grades,
          };

          this.$emit("viewNotification", data);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        const data = {
          type: "error",
          text: "Ocurrio un problema al registrar las notas, por favor intente de nuevo",
          students: [],
        };
        this.$emit("viewNotification", data);
      }
    },
    saveGrades() {
      this.loading = true;
      const data = {
        idGroup: this.group.idGroup,
        idTeacher: this.teacher.idUsuario,
        month: this.group.month,
        type: this.group.type,
        students: this.grades,
      };
      this.insertStudentsGrade(data);
    },
  },
};
</script>

<style>
</style>