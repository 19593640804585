<template>
  <v-dialog
    v-model="dialogDiagnosis"
    persistent
    min-width="500px"
    max-height="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text class="pt-4">
        <v-card class="fill-width mb-3" outlined elevation="0">
          <v-row class="ml-1">
            <v-col cols="12" sm="6" md="6">
              <p class="font-weight-medium text-h6 mb-0" style="color: #1e88e5">
                Carga de Notas
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Grupo:
                <span class="caption">{{ groupDiagnosis.nameGroup }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Tipo de Evaluación:
                <span class="caption">{{ "Diagnóstico" }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          :headers="headersDiagnosis"
          :items="studentsDiagnosis"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
          :items-per-page="-1"
          height="550"
          hide-default-footer
        >
          <template v-slot:[`item.diagnostico`]="{ item }">
            <div style="display: inline-flex" justify="center">
              <v-text-field
                style="width: 50px"
                v-model="item.diagnostico"
                outlined
                dense
                hide-details
              ></v-text-field>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="saveDiagnosis(studentsDiagnosis)"
          :loading="loading"
          color="primary"
          ><v-icon right dark> mdi-content-save </v-icon>Guardar</v-btn
        >
        <v-btn :disabled="loading" @click="closeDialogDiagnosis"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ref, watch } from "vue";
import { useDiagnosis } from "@/composables";
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    students: {
      type: Array,
      default: [],
    },
    group: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const headersDiagnosis = ref(props.headers);
    const groupDiagnosis = ref(props.group);
    const studentsDiagnosis = ref([]);

    const {
      //properties
      dialogDiagnosis,
      loading,
      //methods
      saveDiagnosis,
      closeDialogDiagnosis,
    } = useDiagnosis();

    watch(props, (newValue) => {
      if (newValue.students.length > 0) {
        studentsDiagnosis.value = JSON.parse(JSON.stringify(newValue.students));
      }
    });

    return {
      //properties
      headersDiagnosis,
      groupDiagnosis,
      studentsDiagnosis,
      dialogDiagnosis,
      loading,
      //methods
      saveDiagnosis,
      closeDialogDiagnosis,
    };
  },
};
</script>

<style>
</style>