import { defineStore } from "pinia";
import { useStore } from ".";

export const useDiagnosisStore = defineStore("Diagnosis", {
	state: () => ({
		store: useStore(),
		students: [],
		items: [
			{
				text: "Diagnóstico",
				disabled: false,
				href: "/teacher-diagnosis",
			},
		],
		headers: [
			{
				text: "Nro",
				value: "nro",
				align: "center",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
			{
				text: "Estudiante",
				value: "nombres",
				align: "left",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
			{
				text: "Ci",
				value: "ci",
				align: "center",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
			{
				text: "Diagnóstico",
				value: "diagnostico",
				align: "center",
				sortable: false,
				class: "font-weight-bold blue-grey lighten-5",
			},
		],
		formGroup: {
			idSemester: "",
			idGroup: "",
			nameGroup: "",
		},
		loading: false,
		semesters: [],
		groups: [],
		snackbar: false,
		colorSnackbar: "",
		textSnackbar: "",
		dialogDiagnosis: false,
	}),
	actions: {
		changeDialogDiagnosis(value) {
			this.dialogDiagnosis = value;
		},
		changeSnackbar(value) {
			this.snackbar = value;
		},
		changeColorSnackbar(value) {
			this.colorSnackbar = value;
		},
		changeTextSnackbar(value) {
			this.textSnackbar = value;
		},
		setStudent(value) {
			this.students = [...value];
		},
		clearStudents() {
			this.students = [];
		},
		changeLoading(value) {
			this.loading = value;
		},
		setSemesters(value) {
			this.semesters = [...value];
		},
		clearSemesters() {
			this.semesters = [];
		},
		setGroups(value) {
			this.groups = [...value];
		},
		clearGroups() {
			this.groups = [];
		},
	},
});
