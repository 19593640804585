import {
	getGroupBySemester,
	getSemesterTeacher,
	getStudentsDiagnosis,
	setStudentsDiagnosis,
} from "@/api";
import { useDiagnosisStore } from "@/store/diagnosis";
import { storeToRefs } from "pinia";

export const useDiagnosis = () => {
	const diagnosisStore = useDiagnosisStore();
	const {
		store,
		students,
		headers,
		items,
		formGroup,
		loading,
		semesters,
		groups,
		snackbar,
		colorSnackbar,
		textSnackbar,
		dialogDiagnosis,
	} = storeToRefs(diagnosisStore);

	//api callbacks
	const selectSemesterTeacher = async (idDepartament) => {
		try {
			const response = await getSemesterTeacher(idDepartament);
			if (response.status == 200) {
				diagnosisStore.setSemesters(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const selectGroupBySemester = async (params) => {
		try {
			const response = await getGroupBySemester(params);
			if (response.status == 200) {
				diagnosisStore.setGroups(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const selectStudentsDiagnosis = async (params) => {
		try {
			const response = await getStudentsDiagnosis(params);
			if (response.status == 200) {
				diagnosisStore.setStudent(response.data.students);
				diagnosisStore.changeLoading(false);
			}
		} catch (error) {
			console.log(error);
			diagnosisStore.changeLoading(false);
		}
	};

	const updateStudentsDiagnosis = async (data) => {
		try {
			const response = await setStudentsDiagnosis(data);
			if (response.status) {
				diagnosisStore.setStudent(response.data.students);
				diagnosisStore.changeDialogDiagnosis(false);
				diagnosisStore.changeLoading(false);
				showSnackbar("success", "Se registro las notas de diagnóstico");
			}
		} catch (error) {
			console.log(error);
			diagnosisStore.changeLoading(false);
			showSnackbar("error", "Ocurrio un problema, vuelva a intentarlo");
		}
	};

	//methods
	const semesterFilter = (item, queryText) => {
		const textOne = item.nombreSemestre.toLowerCase();
		const searchText = queryText.toLowerCase();

		return textOne.indexOf(searchText) > -1;
	};
	const groupFilter = (item, queryText) => {
		const textOne = item.nombreGrupo.toLowerCase();
		const searchText = queryText.toLowerCase();

		return textOne.indexOf(searchText) > -1;
	};
	const findGroup = (item) => {
		diagnosisStore.clearGroups();
		diagnosisStore.clearStudents();

		formGroup.value.idGroup = "";

		const params = {
			idDepartament: store.value.state.idDepartament,
			idUser: store.value.state.user.idUsuario,
			idSemester: item,
		};

		selectGroupBySemester(params);
	};

	const findSubject = (item) => {
		formGroup.value.idGroup = item;
		const obj = groups.value.find((group) => group.idGrupo === item);
		formGroup.value.nameGroup = obj.nombreGrupo;
		diagnosisStore.clearStudents();
	};

	const findStudents = () => {
		const params = {
			idGroup: formGroup.value.idGroup,
		};

		diagnosisStore.changeLoading(true);

		selectStudentsDiagnosis(params);
	};

	const editDiagnosis = () => {
		diagnosisStore.changeDialogDiagnosis(true);
	};

	//Dialog diagnosis
	const saveDiagnosis = (students) => {
		const data = {
			idGroup: formGroup.value.idGroup,
			students: students,
		};

		diagnosisStore.changeLoading(true);
		updateStudentsDiagnosis(data);
	};

	const closeDialogDiagnosis = () => {
		diagnosisStore.changeDialogDiagnosis(false);
	};

	const showSnackbar = (type, text) => {
		diagnosisStore.changeSnackbar(true);
		diagnosisStore.changeColorSnackbar(type);
		diagnosisStore.changeTextSnackbar(text);
	};

	return {
		//properties
		store,
		students,
		headers,
		items,
		formGroup,
		loading,
		semesters,
		groups,
		snackbar,
		colorSnackbar,
		textSnackbar,
		dialogDiagnosis,
		//methods
		semesterFilter,
		groupFilter,
		findGroup,
		findSubject,
		findStudents,
		selectSemesterTeacher,
		saveDiagnosis,
		closeDialogDiagnosis,
		editDiagnosis,
	};
};
