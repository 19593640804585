import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import LoginView from "../views/login-view.vue";

import HomeAdministrativeView from "../views/home-administrative-view.vue";
import SemesterView from "../views/groups/semester-view.vue";
import GroupView from "../views/groups/group-view.vue";
import StudentView from "../views/groups/student-view.vue";
import EnrollStudentView from "../views/groups/enroll-student-view.vue";
import PaymentStudentView from "../views/groups/payment-student-view.vue";
import MonthlyPayStudentView from "../views/groups/monthly-pay-student-view.vue";
import TeacherView from "../views/teacher/teacher-view.vue";
import TeacherScheduleView from "../views/teacher/teacher-schedule-view.vue";
import IngressView from "../views/income-expenses/ingress-view.vue";
import EgressView from "../views/income-expenses/egress-view.vue";
import TeacherSubjectScheduleView from "../views/teacher/teacher-subject-schedule-view.vue";
import IndividualHoursStudentView from "../views/individual-hours/individual-hours-student-view.vue";
import TeacherGradeView from "../views/teacher/teacher-grade-view.vue";
import TeacherAttendanceView from "../views/teacher/teacher-attendance-view.vue";
import TeacherDiagnosisView from "../views/teacher/teacher-diagnosis-view.vue";

//report routes
import ReportCashierView from "../views/report/report-cashier-view.vue";
import ReportGeneralView from "../views/report/report-general-view.vue";
import ReportMonthlyPayment from "../views/report/report-monthly-payment-view.vue";
import ReportStudentListView from "../views/report/report-student-list-view.vue";
import ReportIndividualPaymentView from "../views/report/report-individual-payment-view.vue";

//admin routes
import HomeView from "../views/home-view.vue";
import DepartamentView from "../views/administration/departament-view.vue";
import BranchOfficeView from "../views/administration/branch-office-view.vue";
import UserView from "../views/administration/user-view.vue";
import RoleView from "../views/administration/role-view.vue";
import TurnView from "../views/administration/turn-view.vue";
import PaymentType from "../views/administration/payment-type-view.vue";
import SubjectView from "../views/administration/subject-view.vue";
import PaymentCategoryView from "../views/administration/payment-category-view.vue";
import IngressTypeView from "../views/administration/ingress-type-view.vue";
import LessonLevelView from "../views/administration/lesson-level-view.vue";
import StudentAttendanceView from "../views/administration/student-attendance-view.vue";

//grade route
import StudentGradeView from "../views/grade/student-grade-view.vue";
import StudentCredentialView from "../views/credential/student-credential-view.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "login",
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
	},
	{
		path: "/home-administrative",
		name: "home-administrative",
		component: HomeAdministrativeView,
		meta: {
			requiresAuth: true,
		},
	},
	//MonthlyPayment & Groups
	{
		path: "/semester",
		name: "semester",
		component: SemesterView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/semester/:idSemester/group",
		name: "group",
		component: GroupView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/student",
		name: "student",
		component: StudentView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/semester/:idSemester/group/:idGroup",
		name: "enroll",
		component: EnrollStudentView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/semester/:idSemester/group/:idGroup/student/:idStudent",
		name: "payment",
		component: PaymentStudentView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/monthly-pay-student",
		name: "monthly-pay-student",
		component: MonthlyPayStudentView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/monthly-pay-student/group/:idGroup/student/:idStudent",
		name: "payment-student",
		component: PaymentStudentView,
		meta: {
			requiresAuth: true,
		},
	},
	//Individual hours
	{
		path: "/teacher",
		name: "teacher",
		component: TeacherView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/teacher/:idTeacher/schedule",
		name: "teacher-schedule",
		component: TeacherScheduleView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/individual-hours-student",
		name: "individual-hours-student",
		component: IndividualHoursStudentView,
		meta: {
			requiresAuth: true,
		},
	},

	//Ingress & Egress
	{
		path: "/ingress",
		name: "ingress",
		component: IngressView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/egress",
		name: "egress",
		component: EgressView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/ingress/teacher/:idTeacher/schedule",
		name: "teacher-schedule",
		component: TeacherScheduleView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/teacher-diagnosis",
		name: "teacher-diagnosis",
		component: TeacherDiagnosisView,
		meta: {
			requiresAuth: true,
		},
	},
	//Report routes
	{
		path: "/report-cashier",
		name: "report-cashier",
		component: ReportCashierView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report-general",
		name: "report-general",
		component: ReportGeneralView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report-monthly-payment",
		name: "report-monthly-payment",
		component: ReportMonthlyPayment,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report-student-list",
		name: "report-student-list",
		component: ReportStudentListView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report-individual-payment",
		name: "report-individual-payment",
		component: ReportIndividualPaymentView,
		meta: {
			requiresAuth: true,
		},
	},
	//Teacher routes
	{
		path: "/subject-schedule",
		name: "subject-schedule",
		component: TeacherSubjectScheduleView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/grade",
		name: "grade",
		component: TeacherGradeView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/attendance",
		name: "attendance",
		component: TeacherAttendanceView,
		meta: {
			requiresAuth: true,
		},
	},
	//Admin routes
	{
		path: "/home",
		name: "home",
		component: HomeView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/departament",
		name: "departament",
		component: DepartamentView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/branch-office/:id",
		name: "branch-office",
		component: BranchOfficeView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/user",
		name: "user",
		component: UserView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/role",
		name: "role",
		component: RoleView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/turn",
		name: "role",
		component: TurnView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/payment-type",
		name: "payment-type",
		component: PaymentType,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/subject",
		name: "subject",
		component: SubjectView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/payment-category",
		name: "payment-category",
		component: PaymentCategoryView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/ingress-type",
		name: "ingress-type",
		component: IngressTypeView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/lesson-level",
		name: "lesson-level",
		component: LessonLevelView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/student-attendance",
		name: "student-attendance",
		component: StudentAttendanceView,
		meta: {
			requiresAuth: true,
		},
	},
	//gradeRoute
	{
		path: "/student-grade",
		name: "student-grade",
		component: StudentGradeView,
		meta: {
			requiresAuth: true,
		},
	},
	//credentialRoute
	{
		path: "/student-credential",
		name: "student-credential",
		component: StudentCredentialView,
		meta: {
			requiresAuth: true,
		},
	},
	/*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" * '../views/AboutView.vue')
  }*/
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	let user = store.state.user;
	let authorization = to.matched.some((record) => record.meta.requiresAuth);

	if (authorization && !user) {
		next("login");
	} else if (!authorization && user) {
		next("home");
	} else {
		next();
	}
});

export default router;
