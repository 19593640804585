<template>
  <!--vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="700"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        :html-to-pdf-options="htmlToPdfOptions"
        @progress="progress"
        
        ref="html2Pdf"
    -->
  <div ref="pdf" class="mx-5 mt-4 section">
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div
            class="col-12 justify-content-center border-top border-left border-secondary"
          >
            <p class="font-weight-bold mb-0 h5 text-center section">
              INSTITUTO PREUNIVERSITARIO
            </p>
            <p class="font-weight-bold mb-0 h5 text-center section">
              "ISAAC NEWTON"
            </p>
          </div>
          <div class="col-8 px-2 py-2 border-top border-left border-secondary">
            <p class="mb-0 font-weight-bold section">
              Nombre:
              <span class="font-weight-light text-small section">{{
                student.apellidos + " " + student.nombres
              }}</span>
            </p>
          </div>
          <div class="col-4 px-2 py-2 border-top border-left border-secondary">
            <p class="mb-0 font-weight-bold section">
              Grupo:
              <span class="font-weight-light text-small section">
                {{ group.nombreGrupo }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-4 border-top border-left border-right border-secondary">
        <div class="row justify-content-center pt-3">
          <img
            :src="require('../../assets/blueLogo2.png')"
            height="90"
            alt="..."
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 border border-secondary" style="height: 30px"></div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <table cellspacing="0" class="table table-sm table-bordered mb-0">
          <thead class="thead-dark">
            <tr>
              <th v-for="i in headers" :key="i" class="section" scope="col">
                {{ i.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in grades" :key="item.idGrupoMateria">
              <td
                v-for="(col, index) in headers"
                :key="col"
                :style="`${
                  index === 0 ? 'border-left: 1px solid #1486bd' : ''
                }`"
                :class="`py-0 px-1 pt-1 pb-1 ${
                  index === 0 ? 'text-left' : 'text-center'
                } section`"
              >
                {{ item[col.value] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-3 border-contain">
        <p class="mb-0 text-center font-weight-bold section">DIAGNOSTICO</p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section text-small">
          {{
            studentDiagnosis != null &&
            studentDiagnosis >= 0 &&
            studentDiagnosis < 30
              ? `MENOR A 30 PUNTOS = ${studentDiagnosis}`
              : ""
          }}
        </p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section text-small">
          {{
            studentDiagnosis != null &&
            studentDiagnosis >= 30 &&
            studentDiagnosis < 50
              ? `MENOR A 50 PUNTOS = ${studentDiagnosis}`
              : ""
          }}
        </p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section text-small">
          {{
            studentDiagnosis != null && studentDiagnosis >= 50
              ? `MAYOR A 50 PUNTOS = ${studentDiagnosis}`
              : ""
          }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-3 border-contain">
        <p class="mb-0 text-center font-weight-bold section">PMA</p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section">APTO</p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section">NO APTO</p>
      </div>
      <div class="col-3 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center section">NIVELACION</p>
      </div>
    </div>
    <div class="row">
      <div class="col-3 border-contain">
        <p class="mb-0 text-center font-weight-bold section">
          ASISTENCIA AL 100%
        </p>
      </div>
      <div class="col-9 border-contain" style="border-left: 0px">
        <p class="mb-0 text-center font-weight-bold h5 section">
          SU ASISTENCIA ES DE: {{ assistance.totalAssistance }}%
        </p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 border-contain">
        <p class="mb-0 section">OBSERVACIONES:</p>
      </div>
    </div>
  </div>
  <!--/vue-html2pdf-->
</template>

<script>
//import VueHtml2pdf from "vue-html2pdf";
import jsPDF from "jspdf";
import { mapState } from "vuex";
export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    grades: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => {},
    },
    assistance: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    studentDiagnosis: {
      type: String,
      default: "",
    },
  },
  components: {
    //VueHtml2pdf,
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: [0, 0, 0, 0],

        filename: `Boletin.pdf`,

        image: {
          type: "jpeg",
          quality: 1,
        },

        enableLinks: false,

        html2canvas: {
          scale: 2,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
        },
      },
    };
  },
  computed: {
    ...mapState(["idDepartament"]),
  },
  created() {},
  watch: {
    student(newValue) {
      this.htmlToPdfOptions.filename = `Boletin${newValue.ci}.pdf`;
    },
  },
  methods: {
    progress(status) {
      if (status == 100) {
        this.$emit("downloadStop");
      }
    },
    generatePdf() {
      //this.$refs.html2Pdf.generatePdf();
      const pdf = new jsPDF("p", "px", "letter");
      const html = this.$refs.pdf.innerHTML;
      pdf.html(html, {
        callback: (pdf) => {
          pdf.save(`Boletin${this.student.ci}.pdf`);
          this.$emit("downloadStop");
          //pdf.output("pdfjsnewwindow");
        },
        x: 30,
        y: 15,
        width: 400,
        height: 1240,
        windowWidth: 800,
      });
    },
  },
};
</script>

<style scoped>
.border-contain {
  border-right: 1px solid #1486bd;
  border-bottom: 1px solid #1486bd;
  border-left: 1px solid #1486bd;
}
.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.section {
  font-family: Arial, Helvetica, sans-serif !important;
}
/*.section {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}*/
/*.section::before{
		content: "";
		background-image: 
				url('../../assets/blueLogo.png');
		background-size: 500px;
		background-position: center;
		position: absolute;
		top: 0px;
		right: 0px;/*322px;/
		bottom: 0px; /*265px;/
		left: 0px;/*323px;/
		opacity: 0.25;
	}*/
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.text-small {
  font-size: 13px;
}
.text-medium {
  font-size: 11px;
}
.text-tb-small {
  font-size: 10px;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
.text-center {
  text-align: center !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}
.text-right {
  text-align: right !important;
}
small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}
.p-0 {
  padding: 0 !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.text-white {
  color: #fff !important;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
table {
  border-collapse: collapse;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 0px solid #1486bd;
}

.table-bordered th {
  border: 0;
}
.table-bordered td {
  border: 1px solid #1486bd;
  border-left: 0;
}
.table .thead-dark th {
  color: #fff;
  background-color: #1486bd;
  border-color: #1486bd;
  font-size: 14px;
}
table,
tr,
td {
  page-break-inside: avoid;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
</style>