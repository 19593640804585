<template>
  <v-layout align-content-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined>
        <v-card-text>
          <p class="text-h6 mb-0">Diagnóstico</p>
          <v-container>
            <v-form ref="formGradeSearch">
              <v-row class="d-flex flex-row">
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Semestre</p>
                  <v-autocomplete
                    v-model="formGroup.idSemester"
                    :items="semesters"
                    :filter="semesterFilter"
                    outlined
                    dense
                    item-text="nombreSemestre"
                    item-value="idSemestre"
                    label="Seleccione"
                    single-line
                    @change="findGroup"
                    :rules="semesterRules"
                    no-data-text="No hay semestres disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  <p class="mb-2">Grupo</p>
                  <v-autocomplete
                    v-model="formGroup.idGroup"
                    :items="groups"
                    :filter="groupFilter"
                    outlined
                    dense
                    item-text="nombreGrupo"
                    item-value="idGrupo"
                    label="Seleccione"
                    single-line
                    @change="findSubject"
                    :rules="groupRules"
                    no-data-text="No hay grupos disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="py-0" align-self="center">
                  <v-row justify="space-between">
                    <v-btn
                      color="#1486bd"
                      dark
                      elevation="0"
                      :loading="loading"
                      @click="findStudents"
                    >
                      <span class="text-capitalize text-body"
                        >Buscar Grupo</span
                      >
                    </v-btn>
                    <div v-if="students.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            small
                            color="primary"
                            class="mr-2"
                            @click="editDiagnosis"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon dense>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar Diagnóstico</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>

      <template v-if="students.length > 0">
        <v-data-table
          :headers="headers"
          :items="students"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption td-click"
          fixed-header
          :items-per-page="-1"
          height="550"
          hide-default-footer
        >
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  class="mr-1"
                  @click.stop="addGrade(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dense>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar Calificacion</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click.stop="editGrade(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dense>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar Calificacion</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <DialogDiagnosisUpload
        :group="formGroup"
        :headers="headers"
        :students="students"
      />
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
    </v-container>
  </v-layout>
</template>

<script>
import { onMounted, ref } from "vue";
import { useDiagnosis } from "@/composables";
import DialogDiagnosisUpload from "@/components/teacher/dialog-diagnosis-upload.vue";
export default {
  components: {
    DialogDiagnosisUpload,
  },
  setup() {
    const semesterRules = ref([(value) => !!value || "Seleccione un semestre"]);
    const groupRules = ref([(value) => !!value || "Seleccione un grupo"]);
    const {
      //properties
      store,
      students,
      headers,
      items,
      formGroup,
      semesters,
      groups,
      loading,
      snackbar,
      colorSnackbar,
      textSnackbar,
      //methods
      semesterFilter,
      groupFilter,
      findGroup,
      findSubject,
      findStudents,
      selectSemesterTeacher,
      editDiagnosis,
    } = useDiagnosis();

    onMounted(() => {
      selectSemesterTeacher(store.value.state.idDepartament);
    });

    return {
      //properties
      students,
      headers,
      items,
      formGroup,
      semesters,
      groups,
      loading,
      semesterRules,
      groupRules,
      snackbar,
      colorSnackbar,
      textSnackbar,
      //methods
      semesterFilter,
      groupFilter,
      findGroup,
      findSubject,
      findStudents,
      editDiagnosis,
    };
  },
};
</script>

<style>
</style>